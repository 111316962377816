import { useCallback, useEffect } from 'react';

interface Props {
  isOpen: boolean;
  isCloseOnEscape: boolean;
  closeModalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

export const useOnCloseModal = ({
  isOpen,
  isCloseOnEscape,
  closeModalRef,
  onClose,
}: Props) => {
  const onCloseModal = useCallback(
    // https://stackoverflow.com/questions/55092588/typescript-addeventlistener-set-event-type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (closeModalRef.current === event.target && isCloseOnEscape) {
        onClose();
      }
    },
    [closeModalRef, isCloseOnEscape, onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', onCloseModal);
    }
    return () => {
      document.removeEventListener('click', onCloseModal);
    };
  }, [isOpen, onClose, onCloseModal]);
};
