'use client';

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ModalProps } from '@/components/Modal/Modal';
import Modal from '@/components/Modal/Modal';
import { usePathname } from 'next/navigation';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export type ModalContent = ModalProps;

export interface GetModalContentProps<T = unknown> {
  closeModal: (response?: any) => void;
  contentData: T;
}

export type OpenModalProps<T = unknown> = {
  getModalContent: (props: GetModalContentProps<T>) => ModalContent;
  contentData: T;
};
interface ModalContextProps {
  openModal?: <T = unknown>({
    getModalContent,
    contentData,
  }: OpenModalProps<T>) => Promise<unknown>;
  isOpen: boolean;
}

export const ModalContext = createContext<ModalContextProps>({
  isOpen: false,
});

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent | undefined>(
    undefined
  );
  const promiseRef = useRef<any>(undefined);

  const closeModal = useCallback((response?: unknown) => {
    if (promiseRef?.current) {
      promiseRef.current(response);
    }

    setIsOpen(false);
    setModalContent(undefined);
  }, []);

  const actions = useMemo(
    () => ({
      openModal: async <T,>({
        getModalContent,
        contentData,
      }: OpenModalProps<T>) =>
        new Promise((resolve) => {
          setModalContent(getModalContent({ closeModal, contentData }));
          setIsOpen(true);
          promiseRef.current = resolve;
        }),
      isOpen,
    }),
    [closeModal, isOpen]
  );

  useEffect(() => {
    setIsOpen(false);
    setModalContent(undefined);
  }, [pathname]);

  return (
    <ModalContext.Provider value={actions}>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => closeModal(undefined)}
        {...modalContent}
      />
      {children}
    </ModalContext.Provider>
  );
};
