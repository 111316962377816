import { useCallback, useEffect } from 'react';

interface Props {
  isActive: boolean;
  isOpen: boolean;
  callback: () => void;
}

export const useOnEscape = ({ isActive, isOpen, callback }: Props) => {
  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isActive) {
        callback();
      }
    },
    [isActive, callback]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', onEscape);
    }
    return () => {
      document.removeEventListener('keydown', onEscape);
    };
  }, [onEscape, isOpen, callback]);

  return onEscape;
};
