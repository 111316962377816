import { ModalContext, type OpenModalProps } from '@/providers/modal-provider';
import { useContext } from 'react';

export const useModal = () => {
  const { openModal, isOpen } = useContext(ModalContext);

  if (!openModal) {
    throw new Error('Wrap consumer of useModal in ModalProvider');
  }

  return {
    openModal: <T>(props: OpenModalProps<T>) =>
      openModal({
        getModalContent: props.getModalContent,
        contentData: props.contentData,
      }),
    isOpen,
  };
};
